import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';

const ItemCarousel = ({ photos, path, photosCount, scrollTo }) => {
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  SwiperCore.use([Navigation]);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  useEffect(() => {
    if (scrollTo === 'next') {
      goNext();
    } else if (scrollTo === 'prev') {
      goPrev();
    }
  }, []);

  const thumbnail = photos.map((sub, subindex) => {
    const showMore =
      photosCount > photos.length && subindex === photos.length - 1 ? (
        <SwiperSlide key={subindex}>
          <a className="image-link" href={path}>
            <Image fill src={sub.thumbnail} alt="" />
            <div className="show-more">
              {t('cards.item_card.show_more', { count: photosCount })}
            </div>
          </a>
        </SwiperSlide>
      ) : (
        <SwiperSlide key={subindex}>
          <a className="image-link" href={path}>
            <Image fill src={sub.thumbnail} alt="" />
          </a>
        </SwiperSlide>
      );

    return showMore;
  });

  return (
    <Swiper
      ref={swiperRef}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      loop={true}
      lazy={true}
    >
      {thumbnail}
    </Swiper>
  );
};

ItemCarousel.propTypes = {
  photos: PropTypes.array,
  photosCount: PropTypes.number,
  path: PropTypes.string,
  scrollTo: PropTypes.string
};

export default ItemCarousel;
