import React, { Component } from 'react';
import classNames from 'classnames';
import { withApollo } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import { createBookmarkQuery, deleteBookmarkQuery } from '../../queries';
import Bugsnag from '../../utils/bugsnag';

class Bookmark extends Component {
  constructor(...props) {
    super(...props);
    this.state = { isActive: this.props.isActive };
  }

  static propTypes = {
    itemId: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    removeItemFunc: PropTypes.func,
    addBookmark: PropTypes.func,
    deleteBookmark: PropTypes.func,
    client: PropTypes.object
  };

  addBookmark = itemId => {
    const { mutate } = this.props.client;

    mutate({
      variables: {
        itemId
      },
      mutation: createBookmarkQuery
    }).catch(error => {
      Bugsnag.notify(error);
    });
  };

  deleteBookmark = itemId => {
    const { mutate } = this.props.client;

    mutate({
      variables: {
        itemId
      },
      mutation: deleteBookmarkQuery
    })
      .then(() => {
        this.props.client.cache.modify({
          fields: {
            bookmarks(existing, { readField }) {
              return existing.filter(
                item => readField('id', item.item) !== itemId
              );
            }
          },
          broadcast: true
        });
      })
      .catch(error => {
        Bugsnag.notify(error);
      });
  };

  toggleBookmark = e => {
    const { isActive } = this.state;
    const { itemId } = this.props;
    const action = !isActive ? this.addBookmark : this.deleteBookmark;

    e.preventDefault();

    this.setState(previousState => {
      action(itemId);
      return {
        isActive: !previousState.isActive
      };
    });
  };

  render() {
    const { itemId } = this.props;
    const { isActive } = this.state;

    return (
      <div
        className={classNames('bookmarking', 'card_view', { added: isActive })}
      >
        <a
          data-disable="true"
          className={classNames(isActive ? 'remove_bookmark' : 'add_bookmark')}
          data-cy="bookmark-icon"
          data-remote="true"
          data-method="post"
          href={`/bookmarks?card_view=true&amp;item_id=${itemId}`}
          onClick={this.toggleBookmark}
        />
      </div>
    );
  }
}

Bookmark.defaultProps = {
  isActive: false
};

export default withApollo(Bookmark);
